<template>
  <section class="relative isolate w-full overflow-hidden">
    <div class="absolute inset-0 h-full w-full opacity-20">
      <BrandTopography />
    </div>
    <div
      class="absolute inset-0 z-[1] h-full w-full bg-gradient-to-b from-transparent via-neutral-950/50 to-neutral-950"
    />
    <div
      class="relative z-[3] mx-auto grid max-w-[var(--breakpoint-2xl)] items-center justify-center gap-8 px-6 py-8 sm:px-8 sm:py-12 lg:grid-cols-5"
    >
      <!-- Copy -->
      <div class="col-span-1 max-w-prose lg:col-span-3">
        <div
          class="text-base font-bold text-yellow-400 uppercase drop-shadow-lg"
        >
          {{ type }}
        </div>
        <h1
          class="mt-0.5 text-3xl font-extrabold text-white drop-shadow-lg sm:text-5xl"
        >
          {{ blok.headline }}
        </h1>
        <p
          class="mt-2 text-lg font-medium text-neutral-300 drop-shadow-lg sm:text-xl"
        >
          {{ blok.lead }}
        </p>
        <div v-if="blok.hero_link && blok.hero_link.title" class="mt-1">
          <UButton
            v-if="blok.hero_link.linktype !== 'story'"
            variant="link"
            class="-ml-2"
            size="xl"
            :target="blok.hero_link.target"
            trailing-icon="i-lucide-arrow-right"
            :to="blok.hero_link.url"
          >
            {{ blok.hero_link.title }}
          </UButton>
          <UButton
            v-else
            variant="link"
            class="-ml-2"
            size="xl"
            :target="blok.hero_link.target"
            trailing-icon="i-lucide-arrow-right"
            :to="`/${blok.hero_link.cached_url}`"
          >
            {{ blok.hero_link.title }}
          </UButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: '',
  },
});

// Add timer logic
const timePassed = ref(false);

onMounted(() => {
  if (props.blok.images && props.blok.images.length > 1) {
    setTimeout(() => {
      timePassed.value = true;
    }, 5000);
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
